import React, { useContext } from "react"
import { useLocation } from "@reach/router"
import { graphql, Link, useStaticQuery } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import DesktopDropdown from "./DesktopDropdown"
import MobileDropdown from "./MobileDropdown"
import { ChildContext } from "../Layout"

export const activeClassName = "!text-fBlue-800"

export const convertHeaderMenuItem = item => {
  return item.toLowerCase().replace(/ /g, "_")
}

export const removeFeaturesHeading = item => {
  return item.replace("Features - ", "")
}

function HeaderNavItem({ mobile, mobileNavOpen }) {
  const ghostSettings = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          slugs {
            blog
            resources
            features
          }
        }
      }
      allGhostSettings {
        edges {
          node {
            ...GhostSettingsFields
          }
        }
      }
      allGhostTag(
        sort: { name: ASC }
        filter: { visibility: { eq: "public" } }
      ) {
        nodes {
          slug
          name
          visibility
        }
      }
    }
  `)

  const ghostNavigation =
    ghostSettings.allGhostSettings.edges[0].node.navigation
  const ghostResourcesNavigation =
    ghostSettings.allGhostSettings.edges[0].node.secondary_navigation
  const { pathname } = useLocation()
  const url = pathname === "/service/" ? pathname : "/"
  const {
    blog: blogSlug,
    resources: resourcesSlug,
    features: featuresSlug,
  } = ghostSettings.site.siteMetadata.slugs
  const tags = ghostSettings.allGhostTag.nodes

  const featuresMenuList = ghostNavigation.filter(item =>
    item.label.includes("Features - "),
  )

  const { showSearchBox, setShowSearchBox } = useContext(ChildContext)

  const desktopClass = `text-gray-300 hover:text-gray-200 px-2 fLg:px-4 py-2 flex items-center transition duration-150 ease-in-out capitalize`
  const mobileClass = "flex text-gray-300 hover:text-gray-200 py-2"
  const menuClass = mobile ? mobileClass : desktopClass

  return (
    <>
      {ghostNavigation
        .filter(item => !item.label.includes("Features - "))
        .map(item => {
          if (item.url.charAt(0) === "/") {
            if (!mobile && item.url === `${blogSlug}/` && tags.length > 0) {
              return (
                <DesktopDropdown
                  key={item.url}
                  {...{
                    item,
                    child: tags,
                    slug: blogSlug,
                    showAllLink: true,
                    clickHeadToGo: true,
                    desktopClass,
                  }}
                />
              )
            }

            if (
              !mobile &&
              item.url === `${featuresSlug}/` &&
              featuresMenuList.length > 0
            ) {
              return (
                <DesktopDropdown
                  key={item.url}
                  {...{
                    item,
                    child: featuresMenuList,
                    slug: featuresSlug,
                    showAllLink: false,
                    clickHeadToGo: false,
                    desktopClass,
                  }}
                />
              )
            }

            if (!mobile && item.url === `${blogSlug}/` && tags.length > 0) {
              return (
                <DesktopDropdown
                  key={item.url}
                  {...{
                    item,
                    child: tags,
                    slug: blogSlug,
                    showAllLink: true,
                    clickHeadToGo: true,
                    desktopClass,
                  }}
                />
              )
            }

            if (
              !mobile &&
              item.url === `${resourcesSlug}/` &&
              ghostResourcesNavigation.length > 0
            ) {
              return (
                <DesktopDropdown
                  key={item.url}
                  {...{
                    item,
                    child: ghostResourcesNavigation,
                    slug: resourcesSlug,
                    showAllLink: false,
                    clickHeadToGo: false,
                    desktopClass,
                  }}
                />
              )
            }

            if (mobile && item.url === `${blogSlug}/` && tags.length > 0) {
              return (
                <MobileDropdown
                  key={item.url}
                  {...{
                    item,
                    child: tags,
                    slug: blogSlug,
                    showAllLink: true,
                    mobileNavOpen,
                  }}
                />
              )
            }

            if (
              mobile &&
              item.url === `${featuresSlug}/` &&
              featuresMenuList.length > 0
            ) {
              return (
                <MobileDropdown
                  key={item.url}
                  {...{
                    item,
                    child: featuresMenuList,
                    slug: blogSlug,
                    showAllLink: false,
                    mobileNavOpen,
                  }}
                />
              )
            }

            if (
              mobile &&
              item.url === `${resourcesSlug}/` &&
              ghostResourcesNavigation.length > 0
            ) {
              return (
                <MobileDropdown
                  key={item.url}
                  {...{
                    item,
                    child: ghostResourcesNavigation,
                    slug: resourcesSlug,
                    showAllLink: false,
                    mobileNavOpen,
                  }}
                />
              )
            }

            return (
              <li key={item.url}>
                <Link
                  to={item.url}
                  className={menuClass}
                  activeClassName={activeClassName}
                  onClick={() =>
                    window.analytics.track(
                      `landing_${convertHeaderMenuItem(item.label)}_click`,
                    )
                  }
                >
                  {item.label}
                </Link>
              </li>
            )
          }

          if (item.url.charAt(0) === "#") {
            return (
              <li key={item.url}>
                <AnchorLink
                  stripHash={true}
                  to={`${url}${item.url}`}
                  className={menuClass}
                  onAnchorLinkClick={() =>
                    window.analytics.track(
                      `landing_${convertHeaderMenuItem(item.label)}_click`,
                    )
                  }
                >
                  {item.label}
                </AnchorLink>
              </li>
            )
          }

          return (
            <li key={item.url}>
              <a
                href={item.url}
                className={menuClass}
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                  window.analytics.track(
                    `landing_${convertHeaderMenuItem(item.label)}_click`,
                  )
                }
              >
                {item.label}
              </a>
            </li>
          )
        })}

      <li
        className={`cursor-pointer ${menuClass} ${!mobile && "text-gray-400"}`}
        onClick={async () => {
          setShowSearchBox(!showSearchBox)
          await window.analytics.track("landing_search_click")
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
        {mobile && <span className="ml-1">Search</span>}
      </li>
    </>
  )
}

export default HeaderNavItem

import React from "react"
import { safariAnimationFix } from "../utils/Transition"

function Logo() {
  return (
    <svg
      className="w-[161px] h-[21px] opacity-80 hover:opacity-100 transition duration-300 ease-in-out"
      style={safariAnimationFix}
      //width="161"
      //height="21"
      viewBox="0 0 1118 146"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M648.76 44.0585L645.59 42.2073C643.476 40.9731 640.679 39.6156 637.198 38.1346C633.717 36.5302 629.925 35.111 625.822 33.8768C621.719 32.6427 617.679 32.0256 613.701 32.0256C610.592 32.0256 608.044 32.5193 606.055 33.5066C604.065 34.4939 603.071 36.16 603.071 38.5049C603.071 40.7263 604.065 42.5158 606.055 43.8733C606.237 43.9981 606.427 44.1217 606.623 44.2444C608.522 43.4319 610.881 43.0256 613.701 43.0256C617.679 43.0256 621.719 43.6427 625.822 44.8769C629.925 46.111 633.717 47.5303 637.198 49.1346C640.679 50.6156 643.476 51.9731 645.59 53.2073L648.76 55.0585L664.425 25.2541V14.2556L664.424 14.2563L648.76 44.0585Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M667.339 97.9404C666.811 103.455 665.467 108.264 663.306 112.368C660.446 117.675 656.592 121.932 651.744 125.141C646.895 128.35 641.487 130.695 635.519 132.176C629.552 133.533 623.46 134.212 617.244 134.212C610.903 134.212 604.252 133.595 597.29 132.361C590.452 131.003 583.801 129.214 577.336 126.992C570.871 124.648 564.966 121.932 559.62 118.847L559.638 118.809L559.62 118.834L559.62 118.847L559.62 129.847C564.966 132.932 570.871 135.648 577.336 137.992C583.801 140.214 590.452 142.003 597.29 143.361C604.252 144.595 610.903 145.212 617.244 145.212C623.46 145.212 629.552 144.533 635.519 143.176C641.487 141.695 646.895 139.35 651.744 136.141C656.592 132.932 660.446 128.675 663.306 123.368C666.165 117.938 667.595 111.273 667.595 103.375C667.595 101.476 667.51 99.664 667.339 97.9404Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M624.255 100.928C624.96 100.716 625.545 100.457 626.009 100.15C627.625 99.0392 628.433 97.62 628.433 95.8922C628.433 93.3005 627.065 91.3259 624.33 89.9683C621.595 88.4874 617.99 87.1298 613.514 85.8957C609.163 84.5381 604.314 82.9955 598.968 81.2677C591.26 78.676 584.92 75.7758 579.947 72.567C574.974 69.2348 571.306 65.3473 568.944 60.9044C566.592 56.3571 565.411 50.8918 565.401 44.5086V55.4287C565.401 61.8462 566.582 67.3381 568.944 71.9044C571.306 76.3473 574.974 80.2348 579.947 83.567C584.92 86.7758 591.26 89.676 598.968 92.2677C604.314 93.9955 609.163 95.5381 613.514 96.8957C617.949 98.1186 621.53 99.4627 624.255 100.928Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M565.401 44.2058C565.401 44.2511 565.401 44.2964 565.401 44.3418L565.401 44.2058Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M376.822 81.7862V92.7862L351.03 143.581H325.52L299.727 92.7863V81.7863L325.52 132.581H351.03L376.822 81.7862Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M299.727 132.581H261.151V143.581H299.727V132.581Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M376.822 132.581V143.581H415.399V132.581H376.822Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M704.725 49.3626V38.3626H675.399V49.3626H704.725Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M704.725 132.582V143.582H745.301V132.582H704.725Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M745.301 49.3626H774.627V38.3626H745.301V49.3626Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M166.292 132.582V143.582H247.076V132.582H166.292Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M147.791 132.582H107.214V143.582H147.791V132.582Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M1108.34 109.22H1028.93V98.973H1108.34V109.22Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M1028.93 134.837V145.837H1117.3V134.837H1028.93Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M1108.34 73.3566H1028.93V63.1095H1108.34V73.3566Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M1028.93 37.4931H1108.34V38.2471H1117.3V27.247H1028.93V37.4931Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M491.025 134.212C478.82 134.195 466.395 132.355 457.695 128.692C449.068 124.897 442.427 119.319 437.773 111.958C433.119 104.483 430.792 95.2821 430.792 84.3563V95.3563C430.792 106.282 433.119 115.483 437.773 122.958C442.427 130.319 449.068 135.897 457.695 139.692C466.395 143.355 478.82 145.195 491.025 145.212C503.23 145.195 515.655 143.355 524.355 139.692C532.982 135.897 539.623 130.319 544.277 122.958C548.931 115.483 551.258 106.282 551.258 95.3563V84.3563C551.258 95.2821 548.931 104.483 544.277 111.958C539.623 119.319 532.982 124.897 524.355 128.692C515.655 132.355 503.23 134.195 491.025 134.212Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M929.24 126.147C939.493 131.543 951.456 134.242 965.128 134.242C981.806 134.242 995.98 131.115 1007.65 124.861C1007.98 124.684 1008.3 124.52 1008.59 124.365C1010.99 123.119 1012.33 122.424 1012.33 119.663V130.663C1012.33 133.424 1010.99 134.119 1008.6 135.365C1008.3 135.52 1007.98 135.684 1007.65 135.861C995.98 142.115 981.806 145.242 965.128 145.242C951.456 145.242 939.493 142.543 929.24 137.147C918.986 131.75 911.089 124.023 905.55 113.966C900.01 103.908 897.241 92.0114 897.241 78.2745L897.241 67.4651C897.266 81.1214 900.036 92.9549 905.55 102.966C911.089 113.023 918.986 120.75 929.24 126.147Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M897.241 67.0821C897.241 67.0183 897.241 66.9546 897.241 66.8909L897.241 66.8967V67.0821Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M970.143 88.5226V99.5226H974.961C976.411 99.1783 977.73 98.7316 978.917 98.1824V88.5226H970.143Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M1007.97 10.5918L1007.96 10.5905C1007.97 10.5904 1007.97 10.5903 1007.97 10.5903C1007.97 10.5903 1007.49 21.2729 1007.97 21.5918L996.992 50.2948C994.986 49.4429 992.91 48.7121 990.762 48.1025C984.28 46.2628 976.796 45.3429 968.31 45.3429C957.231 45.3429 948.804 48.2252 943.029 53.9897C938.375 58.7319 935.635 65.0513 934.81 72.9476C934.632 71.1431 934.543 69.2521 934.543 67.2745C934.543 56.8492 937.372 48.7543 943.029 42.9897C948.804 37.2252 957.231 34.3429 968.31 34.3429C976.796 34.3429 984.28 35.2628 990.762 37.1025C992.91 37.7121 994.986 38.4429 996.992 39.2948L1007.97 10.5918Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M767.866 132.582H806.825L811.509 110.517H855.358L860.042 132.582H898.998L899.001 132.593V143.582H860.042L855.358 121.517H811.509L806.825 143.582H767.866V132.582Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M767.89 132.497L767.866 132.582V132.574L767.89 132.497Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M847.204 80.9174L833.953 37.6932H832.914L819.663 80.9174H823.035L832.914 48.6932H833.953L843.832 80.9174H847.204Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M0 132.183V143.183H39.9183V132.183H0Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M39.9183 96.1516V85.1516H90.1866V96.1516H39.9183Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M39.9183 45.1115V34.1115H93.1393V45.1115H39.9183Z"
        shapeRendering="geometricPrecision"
        fill="#4440DD"
      />
      <path
        d="M704.725 132.582V38.3628H675.399V1.63086H774.627V38.3628H745.301V132.582H704.725Z"
        shapeRendering="geometricPrecision"
        fill="white"
      />
      <path
        d="M376.822 81.7864V132.581H415.399V1.63049H374.609L338.275 74.8623L325.733 49.5843C325.733 49.5843 315.566 30.7959 301.94 1.63049H261.151V132.581H299.727V81.7864L325.52 132.581H351.03L376.822 81.7864Z"
        shapeRendering="geometricPrecision"
        fill="white"
      />
      <path
        d="M166.292 132.582V1.63086H205.869V94.8498H247.076V132.582H166.292Z"
        shapeRendering="geometricPrecision"
        fill="white"
      />
      <path
        d="M107.214 132.582V1.63086H147.791V132.582H107.214Z"
        shapeRendering="geometricPrecision"
        fill="white"
      />
      <path
        d="M1028.93 109.22H1117.3V134.837H1028.93V109.22Z"
        shapeRendering="geometricPrecision"
        fill="white"
      />
      <path
        d="M1028.93 73.3568H1108.34V98.9732H1028.93V73.3568Z"
        shapeRendering="geometricPrecision"
        fill="white"
      />
      <path
        d="M1028.93 37.4933H1108.34V63.1097H1028.93V37.4933Z"
        shapeRendering="geometricPrecision"
        fill="white"
      />
      <path
        d="M1028.93 1.63086H1117.3V27.2472H1028.93V1.63086Z"
        shapeRendering="geometricPrecision"
        fill="white"
      />
      <path
        d="M457.695 128.692C466.395 132.355 478.82 134.195 491.025 134.212C503.23 134.195 515.655 132.355 524.355 128.692C532.982 124.897 539.623 119.319 544.277 111.958C548.931 104.483 551.258 95.2822 551.258 84.3565L551.258 1.63049H511.628L511.628 78.3522C511.628 84.6776 509.925 89.9104 506.52 94.0507C503.114 98.191 497.95 100.261 491.025 100.261C484.101 100.261 478.936 98.191 475.53 94.0507C472.125 89.9104 470.422 84.6776 470.422 78.3522L470.422 1.63049H430.792L430.792 84.3565C430.792 95.2822 433.119 104.483 437.773 111.958C442.427 119.319 449.068 124.897 457.695 128.692Z"
        shapeRendering="geometricPrecision"
        fill="white"
      />
      <path
        d="M648.76 44.0587C648.76 44.0587 647.703 43.4416 645.59 42.2074C643.476 40.9733 640.679 39.6158 637.198 38.1348C633.717 36.5304 629.925 35.1112 625.822 33.877C621.719 32.6429 617.679 32.0258 613.701 32.0258C610.592 32.0258 608.044 32.5195 606.055 33.5068C604.065 34.4941 603.071 36.1602 603.071 38.505C603.071 40.7265 604.065 42.516 606.055 43.8735C608.044 45.2311 610.841 46.4652 614.446 47.5759C618.176 48.6867 622.59 50.0442 627.687 51.6486C635.892 54.1169 642.979 56.9554 648.946 60.1641C654.914 63.3729 659.514 67.5072 662.746 72.5672C665.979 77.5037 667.595 84.1064 667.595 92.3751C667.595 100.274 666.165 106.938 663.306 112.368C660.446 117.675 656.592 121.933 651.744 125.141C646.895 128.35 641.487 130.695 635.519 132.176C629.552 133.534 623.46 134.212 617.244 134.212C610.903 134.212 604.252 133.595 597.29 132.361C590.452 131.004 583.801 129.214 577.336 126.993C570.871 124.648 564.966 121.933 559.62 118.847L575.284 87.1917C575.284 87.1917 576.528 87.9322 579.014 89.4131C581.501 90.8941 584.795 92.5602 588.898 94.4114C593.001 96.2626 597.538 97.9287 602.511 99.4097C607.609 100.891 612.706 101.631 617.803 101.631C621.782 101.631 624.517 101.137 626.009 100.15C627.625 99.0394 628.433 97.6202 628.433 95.8924C628.433 93.3007 627.065 91.3261 624.33 89.9685C621.595 88.4875 617.99 87.13 613.514 85.8959C609.163 84.5383 604.314 82.9956 598.968 81.2679C591.26 78.6762 584.92 75.7759 579.947 72.5672C574.974 69.235 571.306 65.3475 568.944 60.9046C566.582 56.3383 565.401 50.8464 565.401 44.4289C565.401 34.6792 567.701 26.5339 572.301 19.993C576.901 13.4521 582.93 8.51554 590.39 5.18337C597.974 1.72779 606.117 0 614.819 0C621.284 0 627.5 0.802189 633.468 2.40657C639.56 3.88753 645.217 5.73873 650.438 7.96017C655.784 10.1816 660.446 12.2796 664.425 14.2543L648.76 44.0587Z"
        shapeRendering="geometricPrecision"
        fill="white"
      />
      <path
        d="M965.128 134.242C951.456 134.242 939.493 131.543 929.24 126.147C918.986 120.75 911.089 113.023 905.55 102.966C900.01 92.9086 897.241 81.0115 897.241 67.2747C897.241 53.5378 900.01 41.6408 905.55 31.5834C911.089 21.4035 918.986 13.6152 929.24 8.21858C939.493 2.82197 951.456 0.123657 965.128 0.123657C983.339 0.123657 997.618 3.61309 1007.97 10.592L996.992 39.295C994.986 38.4431 992.91 37.7123 990.762 37.1027C984.28 35.263 976.796 34.3431 968.31 34.3431C957.231 34.3431 948.804 37.2254 943.029 42.9899C937.372 48.7545 934.543 56.8494 934.543 67.2747C934.543 78.1905 937.254 86.4694 942.675 92.1114C948.215 97.6306 956.17 100.39 966.542 100.39C971.61 100.39 975.735 99.6543 978.917 98.1825V88.5227H970.143V63.728H1012.33C1012.33 63.728 1012.33 116.558 1012.33 119.663C1012.33 122.768 1010.64 123.26 1007.65 124.862C995.98 131.115 981.806 134.242 965.128 134.242Z"
        shapeRendering="geometricPrecision"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M767.866 132.582H806.825L811.509 110.517H855.358L860.042 132.582H899.001L861.107 1.63086H805.759L767.866 132.582ZM847.204 80.9176L833.953 37.6934H832.914L819.663 80.9176H847.204Z"
        shapeRendering="geometricPrecision"
        fill="white"
      />
      <path
        d="M0 132.183V1.23254H93.1393V34.1117H39.9183V52.4638H90.1866V85.1518H39.9183V132.183H0Z"
        shapeRendering="geometricPrecision"
        fill="white"
      />
    </svg>
  )
}

export default Logo

module.exports = {
  title:
    "Automatic Script Breakdown, Scheduling and Film Pre-production Service - Filmustage",
  description:
    "Automatic Script Breakdown and Scheduling service. Filmustage makes 100 scenes script breakdown in 1 minute. 24/7, free export in Movie Magic, PDF and Spreadsheets. Designed to save you time on routine tasks. Streamline your production process by using scheduling feature.",
  siteUrl: "https://filmustage.com",
  siteIcon: "filmustage-icon.png",
  rssTitle: "Filmustage Blog",
  slugs: {
    blog: "/blog",
    author: "/author",
    tag: "/tag",
    page: "/page",
    resources: "/resources",
    features: "/features",
  },
  email: "hello@filmustage.com",
  itemsPerFirstPage: 12,
  itemsPerPage: 15,
}

import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import Transition from "../utils/Transition.js"
import { useLocation } from "@reach/router"
import { activeClassName } from "../components/Header/HeaderNavItem"

const IconDrop = () => (
  <svg
    className="w-3 h-3 fill-current text-gray-500 cursor-pointer ml-1.5 flex-shrink-0"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.28 4.305L5.989 8.598 1.695 4.305A1 1 0 00.28 5.72l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z" />
  </svg>
)

function Dropdown({ children, title, to, className, typeBlog, clickHeadToGo }) {
  const ghostSettings = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          slugs {
            blog
          }
        }
      }
    }
  `)
  const { blog: blogSlug } = ghostSettings.site.siteMetadata.slugs
  const { pathname } = useLocation()
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const findActiveItem = children.some(child => {
    const subItems = child.props.children[1]
    return subItems.some(item => item.props.to === pathname)
  })

  return (
    <li
      className="relative"
      onMouseEnter={() => setDropdownOpen(true)}
      onMouseLeave={() => setDropdownOpen(false)}
      onFocus={() => setDropdownOpen(true)}
      onBlur={() => setDropdownOpen(false)}
      onClick={() => setDropdownOpen(!clickHeadToGo)}
      role="presentation"
    >
      {to ? (
        <Link
          className={
            typeBlog
              ? className
              : findActiveItem
                ? `${className} !text-fBlue-800`
                : className
          }
          to={to}
          aria-haspopup="true"
          aria-expanded={dropdownOpen}
          role="button"
          activeClassName={activeClassName}
          partiallyActive={true}
        >
          {title}
          <IconDrop />
        </Link>
      ) : (
        <span
          className={
            findActiveItem || (typeBlog && `${blogSlug}/` === pathname)
              ? `${className} ${activeClassName}`
              : className
          }
          aria-haspopup="true"
          aria-expanded={dropdownOpen}
          role="button"
        >
          {title}
          <IconDrop />
        </span>
      )}

      <Transition
        show={dropdownOpen}
        tag="ul"
        className="flex origin-top-right absolute top-full -left-4 bg-gray-800 py-2 ml-4 rounded-sm w-fit"
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        {children}
      </Transition>
    </li>
  )
}

export default Dropdown

Dropdown.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]),
  title: PropTypes.string.isRequired,
}

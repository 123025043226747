import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import Logo from "../components/Logo"

function Footer() {
  const siteSettings = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  const { pathname } = useLocation()
  const url = pathname === "/service/" ? pathname : "/"
  const { siteUrl } = siteSettings.site.siteMetadata

  return (
    <footer id="contact">
      <div className="py-12 md:py-16">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          {/* Top area: Blocks */}
          <div className="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12 pt-6 md:pt-10 border-t border-gray-800">
            {/* 1st block */}
            <div className="md:col-span-4 lg:col-span-5">
              <div className="mb-2">
                {/* Logo */}
                <AnchorLink
                  stripHash={true}
                  to={`${url}#home`}
                  className="inline-block"
                  aria-label="Filmustage"
                  onClick={() => window.analytics.track("landing_home_click")}
                >
                  <Logo />
                </AnchorLink>
              </div>

              {/* Additional footer text */}
              <div className="text-gray-400">
                <div className="h6 font-bold">Filmustage Headquarter</div>
                <div>Wilmington, Delaware, USA</div>
                <div>
                  <a
                    href="mailto:hello@filmustage.com?subject=Message%20from%20site"
                    className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                    onClick={() => window.analytics.track("landing_mail_click")}
                  >
                    hello@filmustage.com
                  </a>
                </div>
                {/* <div>+1 213.262.0592</div>*/}

                {/*<div className="mt-6">
                  <Link
                    to="/contacts/"
                    className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out underline"
                    onClick={() =>
                      window.analytics.track("landing_terms_click")
                    }
                  >
                    Regional Representatives
                  </Link>
                </div>*/}
              </div>
            </div>

            {/* 2nd, 3rd and 4th blocks */}
            <div className="md:col-span-8 lg:col-span-7 grid grid-cols-3 gap-8">
              {/* 2nd block */}
              <div className="sm:text-sm">
                <div className="h6 text-gray-200 font-medium mb-1">Legals</div>
                <ul>
                  <li className="mb-1">
                    <Link
                      to="/terms/"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                      onClick={() =>
                        window.analytics.track("landing_terms_click")
                      }
                    >
                      Terms of Service
                    </Link>
                  </li>
                  <li className="mb-1">
                    <Link
                      to="/privacy/"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                      onClick={() =>
                        window.analytics.track("landing_privacy_click")
                      }
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  {/*<li className="mb-1">
                    <Link
                      to="/referral-terms/"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                      onClick={() =>
                        window.analytics.track("landing_terms_click")
                      }
                    >
                      Referral Terms
                    </Link>
                  </li>*/}
                </ul>
              </div>

              {/* 3rd block */}
              <div className="sm:text-sm">
                <div className="h6 text-gray-200 font-medium mb-1">
                  Resources
                </div>
                <ul>
                  <li className="mb-1">
                    <Link
                      to="/blog/"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                      onClick={() =>
                        window.analytics.track("landing_blog_click")
                      }
                    >
                      Blog
                    </Link>
                  </li>
                  <li className="mb-1">
                    <Link
                      to="/press-kit/"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                      onClick={() =>
                        window.analytics.track("landing_press_kit_click")
                      }
                    >
                      Press Kit
                    </Link>
                  </li>

                  <li className="mb-1">
                    <a
                      href="https://seo4geo.com"
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                      onClick={() =>
                        window.analytics.track("landing_seo4geo_click")
                      }
                    >
                      Marketing Partner
                    </a>
                  </li>
                  {/*<li className="mb-1">
                    <Link
                      to="/referral-program/"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                      onClick={() =>
                        window.analytics.track("landing_referral_click")
                      }
                    >
                      Referral Program
                    </Link>
                  </li>*/}
                </ul>
              </div>

              {/* 4th block */}
              <div className="sm:text-sm">
                <div className="h6 text-gray-200 font-medium mb-1">Support</div>
                <ul>
                  <li className="mb-1">
                    <a
                      href="https://help.filmustage.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                      onClick={() =>
                        window.analytics.track("landing_help_center_click")
                      }
                    >
                      Help Center
                    </a>
                  </li>
                  <li className="mb-1">
                    <a
                      href="#chat"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                      onClick={e => {
                        e.preventDefault()
                        window.Intercom("show")
                        window.analytics.track("landing_chat_click")
                      }}
                    >
                      Support Chat
                    </a>
                  </li>
                  <li className="mb-1">
                    <a
                      href="mailto:hello@filmustage.com?subject=Message%20from%20site"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                      onClick={() =>
                        window.analytics.track("landing_mail_click")
                      }
                    >
                      Send Email
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Bottom area */}
          <div className="md:flex md:items-center md:justify-between">
            {/* Social links */}
            <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
              <li>
                <a
                  href="https://x.com/filmustage/"
                  target="_blank"
                  rel="noreferrer"
                  className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out"
                  aria-label="Twitter"
                  onClick={() =>
                    window.analytics.track("landing_twitter_click")
                  }
                >
                  <svg
                    className="w-8 h-8 fill-current"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m13.063 9 3.495 4.475L20.601 9h2.454l-5.359 5.931L24 23h-4.938l-3.866-4.893L10.771 23H8.316l5.735-6.342L8 9h5.063Zm-.74 1.347h-1.457l8.875 11.232h1.36l-8.778-11.232Z"></path>
                  </svg>
                </a>
              </li>
              <li className="ml-4">
                <a
                  href="https://www.facebook.com/filmustage/"
                  target="_blank"
                  rel="noreferrer"
                  className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out"
                  aria-label="Facebook"
                  onClick={() =>
                    window.analytics.track("landing_facebook_click")
                  }
                >
                  <svg
                    className="w-8 h-8 fill-current"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                  </svg>
                </a>
              </li>
              <li className="ml-4">
                <a
                  href="https://www.instagram.com/filmustage/"
                  target="_blank"
                  rel="noreferrer"
                  className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out"
                  aria-label="Instagram"
                  onClick={() =>
                    window.analytics.track("landing_instagram_click")
                  }
                >
                  <svg
                    className="w-8 h-8 fill-current"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="20.145" cy="11.892" r="1" />
                    <path d="M16 20c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
                    <path d="M20 24h-8c-2.056 0-4-1.944-4-4v-8c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zm-8-14c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2v-8c0-.935-1.065-2-2-2h-8z" />
                  </svg>
                </a>
              </li>
              <li className="ml-4">
                <a
                  href="https://www.linkedin.com/company/filmustage/"
                  target="_blank"
                  rel="noreferrer"
                  className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out"
                  aria-label="Linkedin"
                  onClick={() =>
                    window.analytics.track("landing_linkedin_click")
                  }
                >
                  <svg
                    className="w-8 h-8 fill-current"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M23.3 8H8.7c-.4 0-.7.3-.7.7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V8.7c-.1-.4-.4-.7-.8-.7zM12.7 21.6h-2.3V14h2.4v7.6h-.1zM11.6 13c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4v-3.7c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8h-2.4V14h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z" />
                  </svg>
                </a>
              </li>
              <li className="ml-4">
                <a
                  href={`https://feedly.com/i/subscription/feed/${siteUrl}/rss.xml`}
                  target="_blank"
                  rel="noreferrer"
                  className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out"
                  aria-label="Linkedin"
                  onClick={() => window.analytics.track("landing_rss_click")}
                >
                  <svg
                    className="w-8 h-8 fill-current"
                    viewBox="-5.1 -4 34 34"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13.86 1.989a2.609 2.609 0 0 0-3.726 0L.768 11.527a2.729 2.729 0 0 0 0 3.795l6.684 6.808a2.618 2.618 0 0 0 1.74.664h5.613a2.616 2.616 0 0 0 1.872-.791l6.554-6.675a2.726 2.726 0 0 0 0-3.795l-9.37-9.544zm-.26 17.422l-.935.95a.372.372 0 0 1-.268.114h-.8a.376.376 0 0 1-.247-.096l-.954-.97a.39.39 0 0 1 0-.542l1.337-1.36a.37.37 0 0 1 .531 0l1.337 1.361a.389.389 0 0 1 0 .543zm0-5.711l-3.737 3.808a.374.374 0 0 1-.268.111h-.799a.376.376 0 0 1-.25-.093l-.951-.97a.391.391 0 0 1 0-.544l4.139-4.214a.372.372 0 0 1 .531 0l1.337 1.362a.386.386 0 0 1 0 .54zm0-5.707l-6.54 6.66a.372.372 0 0 1-.268.113h-.8a.373.373 0 0 1-.249-.094L4.79 13.7a.388.388 0 0 1 0-.54l6.943-7.07a.372.372 0 0 1 .531 0l1.337 1.36a.389.389 0 0 1 0 .543z" />
                  </svg>
                </a>
              </li>
            </ul>
            {/* Copyrights note */}
            <div className="text-gray-400 text-sm mr-4">
              &copy; {new Date().getFullYear()} Filmustage, Inc. All rights
              reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState, createContext } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { focusHandling } from "cruip-js-toolkit"
import { useLocation } from "@reach/router"

import AOS from "aos"
import "instantsearch.css/themes/satellite.css"
import "/src/css/style.scss"

import Header from "./Header"
import Footer from "../partials/Footer"
import { graphql, useStaticQuery } from "gatsby"
import SearchBoxFilm from "./Search/SearchBoxFilm"
import { createInfiniteHitsSessionStorageCache } from "instantsearch.js/es/lib/infiniteHitsCache"

/*export const CheckLighthouse = () => {
  if (typeof window !== "undefined")
    return navigator.userAgent.includes("Chrome-Lighthouse")
}*/
export const ChildContext = createContext(null)
const sessionStorageCache = createInfiniteHitsSessionStorageCache()

const Layout = ({ children }) => {
  // https://www.youtube.com/watch?v=vNDbsdL0vvE
  const data = useStaticQuery(graphql`
    query GhostSettings {
      allGhostSettings {
        edges {
          node {
            ...GhostSettingsFields
          }
        }
      }
    }
  `)
  const settings = data.allGhostSettings.edges[0].node
  const location = useLocation()
  const [showSearchBox, setShowSearchBox] = useState(false)

  useEffect(() => {
    focusHandling("outline")
  }, [location])

  // AOS Gatsby js fix:
  // https://stackoverflow.com/questions/42569360/gatsbyjs-reactjs-aosanimate-on-scroll-causing-build-problems
  // https://github.com/floroz/portfolio-v1
  useEffect(() => {
    AOS.init({
      once: true,
      disable: false,
      duration: 600,
      easing: "ease-out-sine",
    })
  }, [])

  return (
    <ChildContext.Provider
      value={{
        showSearchBox,
        setShowSearchBox,
        sessionStorageCache,
      }}
    >
      <Helmet>
        <body className="font-inter antialiased bg-gray-900 text-gray-200 tracking-tight" />
        <style type="text/css">{`${settings.codeinjection_styles}`}</style>
      </Helmet>

      <div className="flex flex-col min-h-screen overflow-hidden">
        <Header />
        <SearchBoxFilm />
        {children}
        <Footer />
      </div>
    </ChildContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

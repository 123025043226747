import React, { useEffect, useRef } from "react"
import { useInfiniteHits } from "react-instantsearch"

// https://www.algolia.com/doc/guides/building-search-ui/ui-and-ux-patterns/infinite-scroll/react/
function InfiniteHitsFilm({ hitComponent: HitComponent, ...props }) {
  const { hits, isLastPage, showMore } = useInfiniteHits(props)
  const sentinelRef = useRef(null)

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !isLastPage) {
            showMore()
          }
        })
      })

      observer.observe(sentinelRef.current)

      return () => {
        observer.disconnect()
      }
    }
  }, [isLastPage, showMore])

  return (
    <div className="ais-InfiniteHits">
      <ul
        className="ais-InfiniteHits-list h-full bg-fGray-900 overflow-y-auto overscroll-contain  rounded-none"
        style={{ maxHeight: "calc(100vh - 155px)" }}
      >
        {hits.map(hit => (
          <li
            key={hit.objectID}
            className="ais-InfiniteHits-item bg-fGray-900 hover:bg-gray-800 border-t border-gray-700 first:border-none first:rounded-none transition-colors duration-150 ease-in-out"
          >
            <HitComponent hit={hit} />
          </li>
        ))}
        <li
          className="ais-InfiniteHits-sentinel"
          ref={sentinelRef}
          aria-hidden="true"
        />
      </ul>
    </div>
  )
}

export default InfiniteHitsFilm

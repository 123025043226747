/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
const { targetBlank } = require("./src/utils/urls")

const {
  gallery,
  video,
  audio,
  toggle,
  beforeAfter,
} = require("./src/utils/ghost")

exports.onClientEntry = () => {
  //console.log = () => {}
  console.debug = () => {}
  console.info = () => {}
  console.warn = () => {}
}

const LoadAn = async time => {
  if (typeof window.analytics?.load !== "undefined") {
    window.analytics.load(process.env.GATSBY_SEGMENT_PROD_KEY)

    let callbackExecuted = false
    const readyPromise = new Promise(resolve => {
      window.analytics?.ready(() => {
        callbackExecuted = true
        resolve()
      })
    })

    await Promise.race([
      readyPromise,
      new Promise(resolve => {
        setTimeout(() => {
          if (!callbackExecuted) {
            resolve()
          }
        }, time + 1000)
      }),
    ])
  } else {
    await new Promise(resolve => {
      setTimeout(() => resolve(), time)
    })
  }
}

window.analytics?.ready(async function () {
  await window.analytics.track("landing_visit")
})

exports.onRouteUpdate = () => {
  // check if Analytics is loaded before go outside
  const externalLinks = document.querySelectorAll("a[href^='https']")

  externalLinks.forEach(link => {
    link.addEventListener("click", async e => {
      if (link.getAttribute("target") === "_blank") {
        await LoadAn(0)
      } else {
        e.preventDefault()
        await LoadAn(100)
        window.location.href = link.getAttribute("href")
      }
    })
  })

  // Ghost card scripts
  // https://ghost.org/docs/themes/content/
  gallery()
  video()
  audio()
  toggle()
  beforeAfter()
  targetBlank()
}

/*
  https://www.gatsbyjs.com/docs/how-to/performance/add-offline-support-with-a-service-worker/
  https://github.com/gatsbyjs/gatsby/issues/9087#issuecomment-774680408

  exports.onServiceWorkerUpdateReady = () => window.location.reload()
}*/

//import { focusHandling } from "cruip-js-toolkit"
// On change location:
// https://stackoverflow.com/questions/61274365/allow-component-to-detect-route-change-in-gatsby
// https://github.com/gatsbyjs/gatsby/issues/23051#issuecomment-613557479

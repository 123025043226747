import React from "react"
import { useLocation } from "@reach/router"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Logo from "../Logo"
import DesktopMenu from "./DesktopMenu"
import MobileMenu from "./MobileMenu"

function Header() {
  const { pathname } = useLocation()
  const url = pathname === "/service/" ? pathname : "/"

  return (
    <header className="fixed w-full z-30 bg-fGray-900">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-12 sm:h-14">
          {/* Site branding */}
          <div className="flex-shrink-0">
            {/* Logo */}
            <AnchorLink
              stripHash={true}
              to={`${url}#home`}
              className="block"
              aria-label="Filmustage"
              onClick={() => window.analytics.track("landing_home_click")}
            >
              <Logo />
            </AnchorLink>
          </div>

          {/* Desktop navigation */}
          <DesktopMenu />
          {/* Mobile menu */}
          <MobileMenu />
        </div>
      </div>
    </header>
  )
}

export default Header

import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Dropdown from "../../utils/Dropdown"
import { activeClassName } from "./HeaderNavItem"
import { convertHeaderMenuItem, removeFeaturesHeading } from "./HeaderNavItem"
import { AnchorLink } from "gatsby-plugin-anchor-links"

function DesktopDropdown({
  item,
  child,
  slug,
  showAllLink,
  clickHeadToGo,
  desktopClass,
}) {
  const ghostSettings = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          slugs {
            blog
            tag
          }
        }
      }
    }
  `)
  const { blog: blogSlug, tag: tagSlug } = ghostSettings.site.siteMetadata.slugs
  const typeBlog = item.url === `${blogSlug}/`
  const childClass = `whitespace-nowrap font-medium text-sm text-gray-400 hover:text-fBlue-800 flex py-2 px-4 leading-tight`

  const itemsPerFirstColumn = showAllLink ? 7 : 8
  const itemsPerOtherColumns = 8
  const firstColumn = child.slice(0, itemsPerFirstColumn)
  const remainingChilds = child.slice(itemsPerFirstColumn)
  const numOtherColumns = Math.ceil(
    remainingChilds.length / itemsPerOtherColumns,
  )
  const otherColumns = Array.from({ length: numOtherColumns }, (_, i) =>
    remainingChilds.slice(
      i * itemsPerOtherColumns,
      i * itemsPerOtherColumns + itemsPerOtherColumns,
    ),
  )

  const columns = [firstColumn, ...otherColumns]

  return (
    <Dropdown
      title={item.label}
      to={clickHeadToGo && `${slug}/`}
      className={desktopClass}
      typeBlog={typeBlog}
      clickHeadToGo={clickHeadToGo}
    >
      {columns.map((column, columnIndex) => (
        <div key={columnIndex}>
          {columnIndex === 0 && showAllLink && (
            <Link
              to={`${slug}/`}
              className={childClass}
              activeClassName={activeClassName}
            >
              All
            </Link>
          )}

          {column.map((item, i) => {
            if (item.url?.charAt(0) === "/" || typeBlog)
              return (
                <Link
                  key={i}
                  to={
                    typeBlog
                      ? `${blogSlug}${tagSlug}/${item.slug}/`
                      : `${item.url}`
                  }
                  className={childClass}
                  activeClassName={activeClassName}
                >
                  {typeBlog ? item.name : removeFeaturesHeading(item.label)}
                </Link>
              )

            if (item.url.charAt(0) === "#")
              return (
                <AnchorLink
                  key={item.url}
                  stripHash={true}
                  to={`/${item.url}`}
                  className={childClass}
                  onAnchorLinkClick={() =>
                    window.analytics.track(
                      `landing_${convertHeaderMenuItem(item.label)}_click`,
                    )
                  }
                >
                  {removeFeaturesHeading(item.label)}
                </AnchorLink>
              )

            return (
              <a
                key={i}
                href={item.url}
                className={childClass}
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                  window.analytics.track(
                    `landing_${convertHeaderMenuItem(item.label)}_click`,
                  )
                }
              >
                {removeFeaturesHeading(item.label)}
              </a>
            )
          })}
        </div>
      ))}
    </Dropdown>
  )
}

export default DesktopDropdown

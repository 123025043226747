import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import Transition from "../utils/Transition.js"

// close the modal on click outside
export function outsideClickHandler(id, show, handleClose, reverse) {
  return ({ target }) => {
    const element = document.getElementById(id)
    if (show && element) {
      if (reverse ? element.contains(target) : !element.contains(target)) {
        handleClose()
      }
    }
  }
}

// close the modal if the esc key is pressed
export function escKeyHandler(handleClose) {
  return ({ keyCode }) => {
    if (keyCode === 27) {
      handleClose()
    }
  }
}

function Modal({ children, id, ariaLabel, show, handleClose }) {
  const modalContent = useRef(null)

  // close the modal on click outside
  useEffect(() => {
    if (!show) return
    const handler = outsideClickHandler(id, show, handleClose, true)
    document.addEventListener("click", handler)

    return () => {
      document.removeEventListener("click", handler)
    }
  }, [id, show, handleClose])

  // close the modal on click outside
  useEffect(() => {
    if (!show) return
    const handler = escKeyHandler(handleClose)
    document.addEventListener("keydown", handler)

    return () => {
      document.removeEventListener("keydown", handler)
    }
  }, [show, handleClose])

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="fixed inset-0 z-50 bg-black bg-opacity-75 transition-opacity"
        show={show}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />

      {/* Modal dialog */}
      <Transition
        id={id}
        className="fixed inset-0 z-50 overflow-hidden flex items-center justify-center transform px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby={ariaLabel}
        show={show}
        enter="transition ease-out duration-200"
        enterStart="opacity-0 scale-95"
        enterEnd="opacity-100 scale-100"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100 scale-100"
        leaveEnd="opacity-0 scale-95"
      >
        <button
          className="hamburger active"
          aria-controls="mobile-nav"
          aria-expanded={true}
          style={{ position: "absolute", zIndex: 99, top: 30, right: 30 }}
          onClick={handleClose}
        >
          <svg
            className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="4" width="24" height="2" rx="1" />
            <rect y="11" width="24" height="2" rx="1" />
            <rect y="18" width="24" height="2" rx="1" />
          </svg>
        </button>

        <div
          className="bg-white overflow-auto max-w-6xl w-full max-h-full"
          ref={modalContent}
        >
          {children}
        </div>
      </Transition>
    </>
  )
}

export default Modal

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]),
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}

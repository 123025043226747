import React, { useContext } from "react"
import { Link } from "gatsby"
import { Snippet, Highlight } from "react-instantsearch"
import { ChildContext } from "../Layout"
import { useLocation } from "@reach/router"

function Hit({ hit }) {
  const { setShowSearchBox } = useContext(ChildContext)
  const { pathname } = useLocation()
  const isSlugInPathname = pathname.includes(hit.slug)

  return (
    <article>
      <Link to={`/blog/${hit.slug}/`} onClick={() => setShowSearchBox(false)}>
        <div className="container mx-auto">
          <div
            className={`text-lg font-bold mb-2${
              isSlugInPathname && " !text-fBlue-800"
            }`}
          >
            <Highlight attribute="title" hit={hit} />
          </div>
          <div className="mb-3 text-gray-500">
            <Snippet hit={hit} attribute="excerpt" />
          </div>
          <div className="">
            <Snippet hit={hit} attribute="plaintext" />
          </div>
        </div>
      </Link>
    </article>
  )
}

export default Hit

import React, { useContext, useEffect, useState } from "react"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch, SearchBox } from "react-instantsearch"

import Hit from "./Hit"
import { ChildContext } from "../Layout"
import { escKeyHandler, outsideClickHandler } from "../../utils/Modal"
import NoResultsBoundary from "./NoResultsBoundary"
import NoResults from "./NoResults"
import InfiniteHitsFilm from "./InfiniteHitsFilm"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY,
)

function getSearchInputElement() {
  return document.querySelector(".ais-SearchBox-input")
}

function getInfiniteHitsListElement() {
  return document.querySelector(".ais-InfiniteHits-list")
}

// https://stackoverflow.com/questions/71826145/show-keyboard-on-input-focus-without-user-action
export function openIosKeyboard() {
  const input = document.createElement("input")
  input.setAttribute("type", "text")
  input.setAttribute("style", "position: fixed; top: -100px; left: -100px;")
  document.body.appendChild(input)
  input.focus()
  // it's safe to remove the fake input after a 10s timeout
  setTimeout(() => {
    document.body.removeChild(input)
  }, 10 * 1000)
}

let debounceTimer
function SearchBoxFilm() {
  const { showSearchBox, setShowSearchBox, sessionStorageCache } =
    useContext(ChildContext)
  const [hasShown, setHasShown] = useState(false)
  const searchBoxId = "searchBox"

  const closeSearchBox = () => {
    setShowSearchBox(false)
  }

  const handleStateChange = ({ uiState, setUiState }) => {
    if (debounceTimer) clearTimeout(debounceTimer)

    debounceTimer = setTimeout(() => {
      getInfiniteHitsListElement()?.scrollTo(0, 0)
      setUiState(uiState)
    }, 500)
  }

  useEffect(() => {
    if (showSearchBox && !hasShown) {
      openIosKeyboard()
      setHasShown(true)
    }

    if (showSearchBox && hasShown) {
      getSearchInputElement()?.focus()
    }
  }, [showSearchBox, hasShown])

  // close the modal on click outside
  useEffect(() => {
    if (!showSearchBox) return

    const handler = outsideClickHandler(
      searchBoxId,
      showSearchBox,
      closeSearchBox,
    )

    const timer = setTimeout(() => {
      document.addEventListener("click", handler)
    }, 0)

    return () => {
      clearTimeout(timer)
      document.removeEventListener("click", handler)
    }
  }, [searchBoxId, showSearchBox, closeSearchBox])

  // close the modal if the esc key is pressed
  useEffect(() => {
    const handler = escKeyHandler(closeSearchBox)
    document.addEventListener("keydown", handler)

    return () => {
      document.removeEventListener("keydown", handler)
    }
  }, [closeSearchBox])

  return (
    <div
      className={`w-full max-w-2xl mx-auto ${
        showSearchBox ? "block" : "hidden"
      }`}
      id={searchBoxId}
    >
      <div className="fixed mt-12 sm:mt-14 z-10 mx-auto max-w-2xl w-full shadow-md">
        <InstantSearch
          searchClient={searchClient}
          indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
          onStateChange={({ uiState, setUiState }) => {
            handleStateChange({ uiState, setUiState })
          }}
        >
          {hasShown && (
            <>
              <SearchBox
                autoFocus={true}
                placeholder="Search for a blog post..."
                classNames={{
                  root: "border-y border-t-gray-900 border-b-gray-700",
                  form: "bg-fGray-900 relative py-3 px-3 w-full h-full before:ml-2",
                  input:
                    "rounded-sm w-2  py-2 px-9 placeholder-slate-400 focus:outline-none focus:border-fBlue-800 focus:ring-fBlue-800 focus:ring-1",
                  reset: "right-5",
                  loadingIndicator: "right-5",
                }}
              />

              <NoResultsBoundary fallback={<NoResults />}>
                <InfiniteHitsFilm
                  hitComponent={Hit}
                  cache={sessionStorageCache}
                />
              </NoResultsBoundary>
            </>
          )}
        </InstantSearch>
      </div>
    </div>
  )
}

export default SearchBoxFilm

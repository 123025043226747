import React, { useEffect, useRef, useState } from "react"
import HeaderNavItem from "./HeaderNavItem"

function MobileMenu() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const mobileNav = useRef(null)

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target) ||
        target.classList.contains("prevent-close-nav")
      )
        return
      setMobileNavOpen(false)
    }
    document.addEventListener("click", clickHandler)
    return () => document.removeEventListener("click", clickHandler)
  })

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return
      setMobileNavOpen(false)
    }
    document.addEventListener("keydown", keyHandler)
    return () => document.removeEventListener("keydown", keyHandler)
  })
  return (
    <div className="fMd:hidden" style={{ height: 24 }} ref={mobileNav}>
      {/* Hamburger button */}
      <button
        className={`hamburger ${mobileNavOpen && "active"}`}
        aria-controls="mobile-nav"
        aria-expanded={mobileNavOpen}
        onClick={() => {
          setMobileNavOpen(!mobileNavOpen)

          mobileNavOpen
            ? window.analytics.track("landing_mobile_menu_close")
            : window.analytics.track("landing_mobile_menu_open")
        }}
      >
        <span className="sr-only">Menu</span>
        <svg
          className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="4" width="24" height="2" rx="1" />
          <rect y="11" width="24" height="2" rx="1" />
          <rect y="18" width="24" height="2" rx="1" />
        </svg>
      </button>

      {/*Mobile navigation */}
      <nav
        id="mobile-nav"
        className="absolute top-full z-20 left-0 w-full overflow-auto transition-all duration-300 ease-in-out"
        style={
          mobileNavOpen
            ? {
                maxHeight: `calc(100vh - 48px)`,
                opacity: 1,
              }
            : { maxHeight: 0, opacity: 0.7 }
        }
        onClick={e => {
          if (!e.target.classList.contains("prevent-close-nav")) {
            setMobileNavOpen(false)
          }
        }}
        role="presentation"
      >
        <ul className="bg-gray-800 px-6 py-4">
          <HeaderNavItem
            {...{
              mobileNavOpen,
              mobile: true,
            }}
          />
          <li className="pt-2 mt-2 border-t border-gray-700">
            <a
              href="https://app.filmustage.com/sign/in"
              className="flex font-medium w-full text-gray-300 hover:text-gray-200 py-2 justify-center capitalize"
              onClick={async () =>
                await window.analytics.track("landing_sign_in_click")
              }
            >
              Sign in
            </a>
          </li>

          <li>
            <a
              href="https://app.filmustage.com/sign/up"
              className="font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-sm text-gray-800 bg-fOrange-900 hover:bg-fOrange-800 capitalize transition duration-150 ease-in-out"
              onClick={async () =>
                await window.analytics.track("landing_sign_up_click")
              }
            >
              Free Sign Up
            </a>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default MobileMenu

import React from "react"
import { useInstantSearch } from "react-instantsearch"

// https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-display/react/
function NoResultsBoundary({ children, fallback }) {
  const { results, indexUiState } = useInstantSearch()

  // searchQuery?.trim() !== ""
  // The `__isArtificial` flag makes sure not to display the No Results message
  // when no hits have been returned.
  if (
    !results.__isArtificial &&
    results.nbHits === 0 &&
    indexUiState.query !== ""
  ) {
    return (
      <>
        {fallback}
        <div className="hidden">{children}</div>
      </>
    )
  }

  return children
}

export default NoResultsBoundary

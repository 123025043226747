import React from "react"
import { useInstantSearch } from "react-instantsearch"

function NoResults() {
  const { indexUiState } = useInstantSearch()

  return (
    <div className="bg-fGray-900 p-3">
      <p>
        No results for <q>{indexUiState.query}</q>.
      </p>
    </div>
  )
}

export default NoResults
